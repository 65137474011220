<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2 v-text="internalName"></h2>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3">
        <div
          class="row justify-center"
          v-for="(category, i) in categories"
          :key="i"
        >
          <div class="col-sm-12 px-1 py-1">
            <v-expansion-panels tile flat accordion>
              <v-expansion-panel class="white--text rounded-lg">
                <v-expansion-panel-header
                  class="white--text elevation-6 rounded-lg"
                  color="#005c8a"
                >
                  <span v-text="category.name"></span>
                  <div class="mr-auto"></div>
                  <span class="text-right mx-1">VER</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="w-100 d-flex flex-column pl-5">
                    <div class="w-100 py-2 px-2">
                      <div
                        class="w-100 d-flex flex-column justify-left py-1"
                        :class="{
                          'border-bottom-1':
                            x + 1 != category.restaurants.length &&
                            category.restaurants.length > 1,
                        }"
                        v-for="(restaurant, x) in category.restaurants"
                        :key="x"
                      >
                        <div class="d-flex flex-column">
                          <span
                            v-text="restaurant.name"
                            class="font-weight-bold my-0 green__fise"
                          ></span>
                          <span
                            v-text="restaurant.description"
                            class="font-weight-bold my-0 black--text"
                          ></span>
                          <p
                            v-text="restaurant.title"
                            class="mb-0 black--text"
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.w-100 {
  min-width: 100%;
  width: 100%;
}

.green__fise {
  color: #5cb239;
}

.border-bottom-1 {
  border: solid 1px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-color: #e9e9e9;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}

.v-expansion-panel > .v-expansion-panel-header.border-tb {
  border: solid 1px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-color: #e9e9e9;
}

.v-expansion-panel > .v-expansion-panel-header.elevation-6 {
  border-radius: 7px;
}

.v-expansion-panel-content__wrap {
  min-width: 100% !important;
  width: 100% !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 5px !important;
  padding-left: 0px;
}

.v-expansion-panel.white--text >>> i {
  color: white !important;
}
</style>

<script>
import loaderMixin from "@/mixins/loaderMixin";

export default {
  name: "restaurants",
  mixins: [loaderMixin],
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    getRestaurants() {
      this.$axiosApiMobile
        .get("/restaurants?fairId=" + this.fair?.id)
        .then((res) => {
          this.categories = res.data;
          this.hideLoader();
        })
        .catch(() => {
          this.hideLoader();
        });
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "REST")[0]
          ?.internalName ?? "Restaurantes"
      );
    },
  },
  created() {
    this.showLoader();
    this.getRestaurants();
  },
};
</script>
